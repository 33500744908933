import React from 'react';

import { Excalidraw } from "@excalidraw/excalidraw";

import './App.scss';

function App() {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <Excalidraw
          theme='dark'
          autoFocus
        />
      </div>
    </>
  );
}

export default App;
